import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from 'techlify-ng';
import { ModuleWithProviders } from '@angular/core';

const routes: Routes = [
];

import { UserLoginComponent } from './login.component';
routes.push({ path: 'user/login', component: UserLoginComponent });
import { UsersViewAll } from './users-view-all.component';
routes.push({ path: 'users', component: UsersViewAll });
import { RolesViewAllComponent } from './roles-view-all.component';
routes.push({ path: 'rbac/role/all', component: RolesViewAllComponent, canActivate: [AuthGuard] });
import { RolePermissionsManagementComponent } from './role-permissions-management.component';
routes.push({ path: 'rbac/role/permission', component: RolePermissionsManagementComponent, canActivate: [AuthGuard] });
import { UserPasswordEditComponent } from './user-password-edit.component';
routes.push({ path: 'user/password/change', component: UserPasswordEditComponent, canActivate: [AuthGuard] });

/* User Module */
export const UserRoutingProvider: ModuleWithProviders = RouterModule.forRoot(routes, { useHash: true });