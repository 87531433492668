import { Component, OnInit } from '@angular/core';
import { DataManager } from "techlify-ng";
import { DateUtils } from "techlify-ng";

import { Helper } from './helper.model';

/**
 * Component used to view all helpers
 * 
 * @author Saravana kumar
 * @since 20190626
 */
@Component({
    selector: "helpers-view-all",
    templateUrl: "./helpers-view-all.html"
})
export class HelpersViewAllComponent implements OnInit {
    private filters: any;
    private helpers: Helper[];

    private displayAddDialog = false;
    private isWorking = false;
    constructor(private dataManager: DataManager, private dateUtils: DateUtils) {
        this.filters = {};
        this.filters.title = "";
        this.filters.sort_by = "title|ASC";
        this.filters.num_items = "25";
    }

    ngOnInit() {
        this.loadData();
    }

    loadData() {
        this.isWorking = true;
        this.dataManager.GET("api/helpers", this.filters).then(
            res => {
                this.helpers = res.data;
                this.isWorking = false;
            }
        );
    }

    handleDataUpdated() {
        this.displayAddDialog = false;
        this.loadData();
    }

}
