import { Component, OnInit, Input } from '@angular/core';

import { DataManager } from 'techlify-ng';
import { AlertService } from 'techlify-ng';
import { RequestErrorService } from 'techlify-ng';

import { User } from './user.model'
import { Client } from '../client/client.model';

/**
 * Component to view the set of users
 * 
 * @author Joshua Kissoon
 * @since 20190622
 */
@Component({
    selector: 'users-for-client',
    templateUrl: './users-for-client.html'
})
export class UsersForClientComponent implements OnInit {
    @Input() client: Client;
    public isWorking = false;
    public users: User[];
    public filters: any;

    constructor(private dataManager: DataManager, private alertService: AlertService,
        private errorService: RequestErrorService) {
        this.filters = {};
        this.filters.name = "";
        this.filters.email = "";
        this.filters.enabled = true;
        this.filters.sort_by = "id|ASC";
        this.filters.num_items = 25;
    }

    ngOnInit() {
        this.loadData();
    }

    loadData() {
        this.filters.client_id = this.client.id;
        this.isWorking = true;
        this.dataManager.GET("api/users", this.filters).then(
            res => {
                this.users = new Array<User>();
                for (var item of res.items) {
                    var user = new User();
                    user.loadFromMap(item);
                    this.users.push(user);
                }
                this.isWorking = false;
            }
        );
    }

    disableUser(user: User) {
        this.enableDisableUser(user, "disable");
    }

    enableUser(user: User) {
        this.enableDisableUser(user, "enable");
    }

    enableDisableUser(user: User, action: string) {
        this.dataManager.PATCH("api/users/" + user.id + "/" + action, user).then(
            res => {
                this.loadData();
            },
            error => {
                this.errorService.handleError(error);
            }
        );
    }

}

