import { Component, OnInit } from '@angular/core';

import { DataManager } from 'techlify-ng';
import { AlertService } from 'techlify-ng';
import { RequestErrorService } from 'techlify-ng';

import { User } from './user.model'

/**
 * Component to view the set of users
 * 
 * @author Joshua Kissoon
 * @since 20170710
 */
@Component({
    selector: 'users-view-all',
    templateUrl: './users-view-all.html'
})
export class UsersViewAll implements OnInit {
    public displayItemAddDialog = false;
    public isWorking = false;
    public users: User[];
    public filters: any;

    constructor(private dataManager: DataManager, private alertService: AlertService,
        private errorService: RequestErrorService) {
        this.filters = {};
        this.filters.name = "";
        this.filters.email = "";
        this.filters.enabled = true;
        this.filters.sort_by = "id|ASC";
        this.filters.num_items = 20;
    }

    ngOnInit() {
        this.loadData();
    }

    loadData() {
        this.isWorking = true;
        this.dataManager.GET("api/users", this.filters).then(
            res => {
                if (!res.items) {
                    this.alertService.addAlert("Unable to load the set of users", "warning");
                    return;
                }
                this.users = new Array<User>();
                for (var item of res.items) {
                    var user = new User();
                    user.loadFromMap(item);
                    this.users.push(user);
                }
                this.isWorking = false;
            }
        );
    }

    disableUser(user: User) {
        this.enableDisableUser(user, "disable");
    }

    enableUser(user: User) {
        this.enableDisableUser(user, "enable");
    }

    enableDisableUser(user: User, action: string) {
        this.dataManager.PATCH("api/users/" + user.id + "/" + action, user).then(
            res => {
                this.loadData();
            },
            error => {
                this.errorService.handleError(error);
            }
        );
    }

    handleDataUpdated() {
        this.displayItemAddDialog = false;
        this.loadData();
    }

}

