import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { DataManager } from 'techlify-ng';
import { AlertService } from 'techlify-ng';
import { AuthService } from 'techlify-ng';
import { ConfigService } from 'techlify-ng';
import { RequestErrorService } from 'techlify-ng';

import { CurrentUserService } from "../user/current-user.service";

import { User } from "techlify-ng";

@Component({
    selector: "user-login",
    templateUrl: "./login.html",
})
export class UserLoginComponent implements OnInit {
    public user: User;
    public isWorking = false;

    constructor(private dataman: DataManager, private alerter: AlertService,
        private router: Router, private authService: AuthService,
        private config: ConfigService, private currentUserService: CurrentUserService,
        private errorService: RequestErrorService) {
        this.user = new User();
    }

    ngOnInit() {
        if (this.authService.isAuthenticated()) {
            this.alerter.addAlert("You are already logged in. Please log out to continue. ", "warn");
            this.router.navigate(["dashboard"]);
        }
    }

    login() {
        this.isWorking = true;

        var data = {
            client_id: 2,
            client_secret: this.config.getAuthClientSecret(),
            grant_type: this.config.getAuthGrantType(),
            username: this.user.email,
            password: this.user.password
        }
        this.dataman.POST("oauth/token", data).then(
            res => {
                if (!res.access_token) {
                    this.alerter.addAlert("Incorrect email and/or password entered. ", "warn");
                    this.isWorking = false;
                    return;
                }

                this.authService.authenticate(res);

                /* Lets load the current user */
                this.currentUserService.getCurrentUser().then(
                    res => {
                        if (res.user.user_type_id != 1) {
                            this.alerter.addAlert("Sorry, you're not allowed access to this app. ", "warning");
                            this.authService.signOut();
                            return;
                        }

                        this.alerter.addAlert("Successfully Logged In. You will be taken to the dashboard in a moment. ", "success");
                        this.user = new User();
                        this.user.loadFromMap(res.user);
                        this.router.navigate(["dashboard"]);
                    });
            },
            error => {
                if (error.status == 401) {
                    this.alerter.addAlert("Incorrect username and/or password, please try again. ", "warning");
                }
                else {
                    this.errorService.handleError(error);
                }
                this.isWorking = false;
            }
        );

    }
}