import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from 'techlify-ng';

const routes: Routes = [
];

import { HelpersViewAllComponent } from './helpers-view-all.component';
routes.push({ path: 'helpers', component: HelpersViewAllComponent, canActivate: [AuthGuard] });

export const HelperRoutingProvider = RouterModule.forRoot(routes, { useHash: true });