import { Component, OnInit, Input } from '@angular/core';

import { DataManager } from "techlify-ng";
import { AlertService } from "techlify-ng";

import { Role } from './role.model';
import { Client } from '../client/client.model';

/**
 * Component used to view a set of roles
 * 
 * @author Joshua Kissoon
 * @since 20160818
 */
@Component({
    selector: 'roles-view-all',
    templateUrl: './roles.html',
})
export class RolesViewAllComponent implements OnInit {
    @Input() client: Client;
    roles: Role[];

    constructor(private dataManager: DataManager, private alertService: AlertService) { }

    ngOnInit() {
        this.loadData();
    }

    loadData() {
        const filters:any = {};

        if(this.client) {
            filters.client_id = this.client.id;
        }
        this.dataManager.GET("api/roles", filters).then(
            res => {
                this.roles = res.data;
            }
        );
    }

}
