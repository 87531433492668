/**
 * Class represnetation of a client payment
 * 
 * @author Floyd Kissoon
 * @since 20190605
 */
export class ClientPayment {
    id: number;
    amount_due: number;
    subscription_id: number;
    amount_paid: number;
    paid_on: Date;
    paid: boolean;
    created_at: Date;
    creator_id: number;

    creator: any;
    subscription: any;

    constructor() {
        this.creator = {};
        this.subscription = {};
    }
}