import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

import { ModuleDataService } from "../module-data.service";
import { Module } from "../module.model";
import { ModulePackage } from './module-package.model';

@Component({
    selector: "module-package-select",
    templateUrl: "./module-package-select.html"
})
export class ModulePackageSelectComponent implements OnInit {
    private modules: Module[];
    private packages: ModulePackage[];

    @Input() id: number;
    @Output() idChange: EventEmitter<number>;

    @Input() moduleId: number;
    @Output() moduleIdChange: EventEmitter<number>;

    constructor(private dataService: ModuleDataService) {
        this.modules = new Array<Module>();
        this.packages = [];
        this.idChange = new EventEmitter<number>();
        this.moduleIdChange = new EventEmitter<number>();
    }

    ngOnInit() {
        this.loadData();
    }

    private loadData() {
        this.dataService.getModules().then(
            res => {
                this.modules = res.data;
            }
        );
    }

    itemSelected() {
        this.idChange.emit(this.id);
    }

    moduleSelected() {
        this.moduleIdChange.emit(this.moduleId);

        if(null == this.getSelectedModule()) {
            this.moduleId = 0;
            this.packages = [];
            return;
        }

        const mod = this.getSelectedModule();
        this.packages = mod.packages;
    }

    private getSelectedModule() {
        for(let module of this.modules) {
            if(module.id == this.moduleId) {
                return module;
            }
        }

        return null;
    }

}