import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from 'techlify-ng';

const routes: Routes = [
];
import { ClientsViewAllComponent } from "./clients-view-all.component";
routes.push({ path: 'clients', component: ClientsViewAllComponent, canActivate: [AuthGuard] });

import { ClientViewComponent } from './client-view.component';
routes.push({ path: 'client/:id/view', component: ClientViewComponent, canActivate: [AuthGuard] });

export const ClientRoutingProvider = RouterModule.forRoot(routes, { useHash: true });