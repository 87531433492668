/**
 * Class representation of a client subscription
 * 
 * @author Floyd Kissoon
 * @since 20190605
 */
export class ClientSubscription {
    id: number;
    client_id: number;
    module_id: number;
    package_id: number;
    start_date: Date;
    monthly_cost: number;
    status_id: number;
    created_at: Date;

    client: any;
    package: any;
    status: any;
    
    constructor() {
        this.client = {};
        this.status = {};
        this.package = {};
    }
}