import { Component, Input, Output, EventEmitter } from '@angular/core';

import { AlertService } from "techlify-ng";
import { DataManager } from "techlify-ng";

import { File } from "./File.model";

/**
* Controller used to edit a File
* 
* @author Joshua Kissoon
* @since 20180125
*/
@Component({
    selector: "file-edit",
    templateUrl: "./file-form.html"
})
export class FileEditComponent
{
    @Input() file: File;
    @Output() onSave = new EventEmitter<File>();

    constructor(private dataManager: DataManager, private alerter: AlertService) { }

    saveFile()
    {
        this.dataManager.PUT("api/files/" + this.file.id, this.file).then(
            res =>
            {
                if (res.success != true)
                {
                    this.alerter.addAlert(res.message, "warning");
                }

                this.onSave.emit(res.file);
                this.alerter.addAlert(res.message, "success");
            }
        );
    }
}
