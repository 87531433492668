import { Component, Input, Output, EventEmitter } from '@angular/core';

import { DataManager } from "techlify-ng";
import { AlertService } from "techlify-ng";
import { RequestErrorService } from "techlify-ng";

import { Helper } from "./helper.model";

/**
 * Controller used to delete a helper
 * 
 * @author Saravana kumar
 * @since 20190719
 */
@Component({
    selector: 'helper-delete',
    templateUrl: "./helper-delete.html"
})
export class HelperDeleteComponent {
    @Input() helper: Helper;
    @Output() onSave = new EventEmitter<Helper>();
    private isWorking = false;

    constructor(private dataManager: DataManager, private alertService: AlertService,
        private errorHandler: RequestErrorService) { }

        deleteHelper() {
        this.isWorking = true;
        this.dataManager.DELETE("api/helpers/" + this.helper.id).then(
            response => {
                this.alertService.addAlert("Successfully deleted", "success");
                this.onSave.emit(response.item);
                this.isWorking = false;
            }, error => {
                this.errorHandler.handleError(error);
                this.isWorking = false;
            }
        );
    }
}
