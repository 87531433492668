import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

import { UserDataService } from "./user-data.service";
import { User } from "techlify-ng";

@Component({
    selector: "user-selector",
    templateUrl: "./user-selector.html"
})
export class UserSelectorComponent implements OnInit {
    public items: User[];

    @Input() id: string;
    @Output() idChange: EventEmitter<string>;

    constructor(private dataService: UserDataService) {
        this.items = new Array<User>();
        this.idChange = new EventEmitter<string>();
    }

    ngOnInit() {
        this.loadData();
    }

    private loadData() {
        this.dataService.getUsers().then(
            res => {
                this.items = res.items;
            }
        );
    }

    itemSelected() {
        this.idChange.emit(this.id);
    }

}