import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DataManager } from 'techlify-ng';
import { RequestErrorService } from 'techlify-ng';
import { AlertService } from 'techlify-ng';

import { Module } from './module.model';

/**
 * Controller used to view a single module
 * 
 * @author Floyd Kissoon
 * @since 20190604
 */
@Component({
    selector: 'module-view',
    templateUrl: './module-view.html'
})
export class ModuleViewComponent implements OnInit {

    private module = new Module();

    constructor(private dataManager: DataManager, private route: ActivatedRoute, private errorHandler: RequestErrorService,
        private alertService: AlertService, ) { }

    ngOnInit() {
        this.loadData();
    }

    loadData() {
        this.route.params.subscribe(params => {
            if (params['id'] !== undefined) {
                let id = params['id'];

                this.dataManager.GET('api/modules/' + id).then(
                    res => {
                        this.module = res.item;
                    },
                    error => {
                        this.errorHandler.handleError(error);
                    }
                );
            }
        });
    }
}
