import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from 'techlify-ng';
import { DashboardComponent } from './dashboard.component';

const routes: Routes = [
    { path: 'home', component: DashboardComponent, canActivate: [AuthGuard] },
    { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] }
];

export const DashboardRoutingProvider = RouterModule.forRoot(routes, { useHash: true });