import { Component, OnInit, Input } from '@angular/core';

import { DataManager } from "techlify-ng";
import { RequestErrorService } from 'techlify-ng';

import { AutoDealerInformation } from './auto-dealer-information.model';
import { Client } from '../client/client.model';

/**
 * Component used to view auto dealer Information
 * 
 * @author Floyd Kissoon
 * @since 20190815
 */
@Component({
    selector: "auto-dealer-information-for-client",
    templateUrl: "./auto-dealer-information-for-client.html"
})
export class AutoDealerInformationForClientComponent implements OnInit {
    @Input() client: Client;
    private filters: any;
    private autoDealerInfo: AutoDealerInformation[];

    constructor(private dataManager: DataManager,
        private errorHandler: RequestErrorService) {
    }

    ngOnInit() {
        this.loadData();
    }

    loadData() {

        this.dataManager.GET('api/auto-dealer-information/' + this.client.id).then(
            res => {
                this.autoDealerInfo = res.item;
            },
            error => {
                this.errorHandler.handleError(error);
            }
        );
    }

}
