/**
 * Class representation of a Receipt Format
 *
 * @author Floyd Kissoon
 * @since 20191006
 */
export class ReceiptFormat {
  id: number;
  title: number;
  template_code: string;
  entity_code: string;
  created_at: Date;
  updated_at: Date;
  client_id: number;
  status_id: number

  status: any;
  client: any;

  constructor() {
    this.client = {};
    this.status = {};
  }
}
