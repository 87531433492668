import { Component, Input, Output, EventEmitter } from '@angular/core';

import { DataManager } from 'techlify-ng';
import { AlertService } from 'techlify-ng';
import { RequestErrorService } from 'techlify-ng';

import { Module } from './module.model';

/**
 * Controller used to delete a Module
 * 
 * @author Floyd Kissoon
 * @since 20190603
 */
@Component({
    selector: 'module-delete',
    templateUrl: './module-delete.html'
})
export class ModuleDeleteComponent {
    @Input() module: Module;
    @Output() onSave = new EventEmitter<Module>();
    private isWorking = false;

    constructor(private dataManager: DataManager, private alertService: AlertService,
        private errorHandler: RequestErrorService) { }

    delete() {
        this.isWorking = true;

        this.dataManager.DELETE('api/modules/' + this.module.id).then(
            res => {
                this.onSave.emit(this.module);
                this.alertService.addAlert('Successfully deleted Module', 'success');
                this.isWorking = false;
            },
            error => {
                this.errorHandler.handleError(error);
                this.isWorking = false;
            }
        );
    }
}