import { Component, Input, Output, EventEmitter } from '@angular/core';

import { DataManager } from 'techlify-ng';
import { AlertService } from 'techlify-ng';
import { RequestErrorService } from 'techlify-ng';

import { Module } from './module.model';

/**
 * Controller used to edit a Module
 * 
 * @author Floyd Kissoon
 * @since 20190604
 */
@Component({
    selector: 'module-edit',
    templateUrl: './module-form.html'
})
export class ModuleEditComponent {

    @Input() module: Module;
    @Output() onSave = new EventEmitter<Module>();
    private isWorking = false;
    constructor(private dataManager: DataManager, private alertService: AlertService,
        private errorHandler: RequestErrorService) { }

    save() {
        this.isWorking = true;
        this.dataManager.PUT('api/modules/' + this.module.id, this.module).then(
            res => {
                this.onSave.emit(res.item);
                this.alertService.addAlert('Successfully updated module', 'success');
                this.isWorking = false;
            },
            error => {
                this.errorHandler.handleError(error);
                this.isWorking = false;
            }
        );
    }
}