import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { enableProdMode } from '@angular/core';
enableProdMode();

/* External Components */
import { GrowlModule } from 'primeng/primeng';
import { CalendarModule } from 'primeng/primeng';
import { DialogModule } from 'primeng/primeng';
import { FileUploaderModule } from '../file-uploader/file-uploader.module';

/* Routing Provider */
import { SchoolInformationRoutingProvider } from './auto-dealer-information.routes';

import { AutoDealerInformationAddComponent } from './auto-dealer-information-add.component';
import { AutoDealerInformationEditComponent } from './auto-dealer-information-edit.component';
import { AutoDealerInformationForClientComponent } from './auto-dealer-information-for-client.component';


@NgModule({
    imports: [
        BrowserModule, FormsModule,
        SchoolInformationRoutingProvider, CalendarModule, DialogModule,
        GrowlModule,
        BrowserAnimationsModule,
        FileUploaderModule
    ],
    declarations: [
        AutoDealerInformationAddComponent,
        AutoDealerInformationEditComponent,
        AutoDealerInformationForClientComponent
        
    ],
    providers: [],
    exports: [AutoDealerInformationForClientComponent]
})
export class AutoDealerInformationModule { }
