import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DataManager } from 'techlify-ng';
import { RequestErrorService } from 'techlify-ng';
import { AlertService } from 'techlify-ng';

import { Client } from './client.model';

/**
 * Controller used to view a single client
 * 
 * @author Floyd Kissoon
 * @since 20190604
 */
@Component({
    selector: 'client-view',
    templateUrl: './client-view.html',
    styleUrls: ['./client.scss']
})
export class ClientViewComponent implements OnInit {
    private client: Client;

    constructor(private dataManager: DataManager, private route: ActivatedRoute,
        private errorHandler: RequestErrorService) { }

    ngOnInit() {
        this.loadData();
    }

    loadData() {
        this.route.params.subscribe(params => {
            if (params['id'] !== undefined) {
                let id = params['id'];

                this.dataManager.GET('api/clients/' + id).then(
                    res => {
                        this.client = res.item;
                    },
                    error => {
                        this.errorHandler.handleError(error);
                    }
                );
            }
        });
    }
}
