import { Component, Input, Output, EventEmitter } from '@angular/core';

import { DataManager } from 'techlify-ng';
import { AlertService } from 'techlify-ng';
import { RequestErrorService } from 'techlify-ng';

import { Client } from './client.model';

/**
 * Controller used to delete a client
 * 
 * @author Floyd Kissoon
 * @since 20190603
 */
@Component({
    selector: 'client-delete',
    templateUrl: './client-delete.html'
})
export class ClientDeleteComponent {
    @Input() client: Client;
    @Output() onSave = new EventEmitter<Client>();
    private isWorking = false;

    constructor(private dataManager: DataManager, private alertService: AlertService,
        private errorHandler: RequestErrorService) { }

    delete() {
        this.isWorking = true;

        this.dataManager.DELETE('api/clients/' + this.client.id).then(
            res => {
                this.onSave.emit(this.client);
                this.alertService.addAlert('Successfully deleted Client', 'success');
                this.isWorking = false;
            },
            error => {
                this.errorHandler.handleError(error);
                this.isWorking = false;
            }
        );
    }
}