import { Component, Input, Output, EventEmitter } from "@angular/core";

import { DataManager } from "techlify-ng";

/**
 * Pluggable component to handle uploading a file to the file temporary directory
 * 
 * @author Joshua Kissoon
 * @since 20170418
 */
@Component({
    selector: "file-uploader",
    templateUrl: "./file-uploader.html"
})
export class FileUploader
{
    @Input() fname: string;
    @Output() fnameChange: EventEmitter<string>;
    @Output() onUploadStarted: EventEmitter<boolean>;
    @Output() onUploadCompleted: EventEmitter<boolean>;

    private file: any;

    private uploaderDisabled = false;
    private helpText = "";

    constructor(private dataManager: DataManager) {
        this.fnameChange = new EventEmitter<string>();
        this.onUploadStarted = new EventEmitter<boolean>();
        this.onUploadCompleted = new EventEmitter<boolean>();
    }

    @Input() set currentFileName(value: string)
    {
        this.helpText = "Current File: " + value;
    }

    uploadFile(event)
    {
        this.uploaderDisabled = true;
        this.helpText = "Uploading File...";
        this.onUploadStarted.emit(true);

        let fileList: FileList = event.target.files;
            let file: File = fileList[0];
            this.file = file;

        let formData: FormData = new FormData();
        formData.append('fileData', this.file, this.file.name);

        this.dataManager.POSTRAW("api/files/upload", formData).then(
            res => {
                /* Lets update the fname value */
                this.fname = res.file;
                this.fnameChange.emit(this.fname);

                this.uploaderDisabled = false;
                this.helpText = "File Successfully Uploaded";
                this.onUploadCompleted.emit(true);
            }
        );
    }

    /**
     * Clear the form data
     */
    clearData()
    {
        this.helpText = "";
    }

}
