import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DataManager } from "techlify-ng";
import { AlertService } from "techlify-ng";

import { File } from "../file-uploader/File.model";

/**
 * Component used to add a File
 * 
 * @author Joshua Kissoon
 * @since 20180125
 */
@Component({
    selector: "file-add",
    templateUrl: "./file-form.html",
})
export class FileAddComponent implements OnInit
{
    @Output() onSave = new EventEmitter<File>();
    @Input() owner_id: number;
    @Input() owner_type: string;
    
    private file: File;

    constructor(private dataManager: DataManager, private alertService: AlertService, private route: ActivatedRoute)
    {
        this.file = new File();
    }

    ngOnInit()
    {
        this.file.owner_id = this.owner_id;
        this.file.owner_type = this.owner_type;
    }

    saveFile()
    {
        this.dataManager.POST("api/files", this.file).then(
            res =>
            {
                if (res.success != true)
                {
                    this.alertService.addAlert(res.message, "warning");
                }

                this.onSave.emit(res.file);
                this.alertService.addAlert(res.message, "success");
            }
        );
    }
}