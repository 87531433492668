import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

/* External Components */
import { GrowlModule } from 'primeng/primeng';
import { DialogModule } from 'primeng/primeng';

import { UtilityModule } from 'techlify-ng';
import { UserModule } from '../user/user.module';
import { ClientSubscriptionModule } from '../client-subscription/client-subscription.module';
import { AutoDealerInformationModule } from '../auto-dealer-information /auto-dealer-information.module';

/* Routing Provider */
import { ClientRoutingProvider } from './client.routes';

import { ClientsViewAllComponent } from './clients-view-all.component';
import { ClientAddComponent } from './client-add.component';
import { ClientEditComponent } from './client-edit.component';
import { ClientDeleteComponent } from './client-delete.component';
import { ClientViewComponent } from './client-view.component';
import { ClientDataService } from './client-data.service';
import { ClientSelectComponent } from './client-selector.component';

@NgModule({
    imports: [
        BrowserModule, FormsModule,
        DialogModule,
        GrowlModule,
        BrowserAnimationsModule,
        UserModule,
        ClientRoutingProvider, UtilityModule,
        ClientSubscriptionModule,
        AutoDealerInformationModule
    ],
    declarations: [
        ClientsViewAllComponent,
        ClientAddComponent,
        ClientEditComponent,
        ClientDeleteComponent,
        ClientViewComponent,
        ClientSelectComponent
    ],
    providers: [ClientDataService],
    exports: [ClientSelectComponent]
})
export class ClientModule { }
