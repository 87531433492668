import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { DialogModule } from 'primeng/primeng';

import { enableProdMode } from '@angular/core';
enableProdMode();

/* Core Services */
import { AuthGuard } from 'techlify-ng';
import { AuthService } from 'techlify-ng';
import { DataManager } from "techlify-ng";
import { AlertService } from "techlify-ng";

/* Payment Components */
import { FileUploader } from './file-uploader.component';
import { FileAddComponent } from './file-add.component';
import { FileEditComponent } from './file-edit.component';
import { FileDeleteComponent } from './file-delete.component';
import { FilesForModelComponent } from './files-for-model.component';


@NgModule({
    imports: [
        BrowserModule, FormsModule,
        DialogModule,

    ],       // module dependencies
    declarations: [
        FileUploader, FileAddComponent, FileEditComponent, FileDeleteComponent, 
        FilesForModelComponent
    ],
    providers: [AuthGuard, DataManager, AuthService, AlertService],
    exports: [FileUploader, FileAddComponent, FileEditComponent, FileDeleteComponent, FilesForModelComponent]
})
export class FileUploaderModule { }
