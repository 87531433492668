import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { enableProdMode } from '@angular/core';
enableProdMode();

/* External Components */
import { DialogModule } from 'primeng/primeng';
import { EditorModule } from 'primeng/editor';


/* Routing Provider */
import { HelperRoutingProvider } from './helper.routes';

import { HelperAddComponent } from './helper-add.component';
import { HelperEditComponent } from './helper-edit.component';
import { HelperDeleteComponent } from './helper-delete.component';
import { HelpersViewAllComponent } from './helpers-view-all.component';

@NgModule({
    imports: [
        BrowserModule, FormsModule,
        HelperRoutingProvider, DialogModule,
        BrowserAnimationsModule,
        EditorModule
    ],
    declarations: [
        HelperAddComponent,
        HelperEditComponent,
        HelperDeleteComponent,
        HelpersViewAllComponent
    ],
    providers: [],
    exports: []
})
export class HelperModule { }
