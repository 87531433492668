/*
 * Class representation of a Helper
 * 
 * @author Saravana kumar
 * @since 20190719
 */
export class Helper {
    id: number;
    title: string;
    body: string;
    code: string;
    video_url: string;
}