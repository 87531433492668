import { Component, Output, EventEmitter, Input } from '@angular/core';

import { DataManager } from 'techlify-ng';
import { AlertService } from 'techlify-ng';
import { RequestErrorService } from 'techlify-ng';

import { ClientSubscription } from "./client-subscription.model";
import { Client } from '../client/client.model';

/**
 * Controller that adds a new record
 * 
 * @author Joshua Kissoon
 * @since 20190622
 */
@Component({
    selector: 'client-subscription-add',
    templateUrl: './client-subscription-form.html'
})
export class ClientSubscriptionAddComponent {
    @Input() client: Client;
    @Output() onSave = new EventEmitter<ClientSubscription>();
    private subscription: ClientSubscription;

    private isWorking = false;

    constructor(private dataManager: DataManager, private alertService: AlertService,
        private errorHandler: RequestErrorService) { }

    initForm() {
        this.subscription = new ClientSubscription();
        this.subscription.module_id = 0;
        this.subscription.package_id = 0;
        this.subscription.start_date = new Date();
        this.subscription.client_id = this.client.id;
    }

    ngOnInit() {
        this.initForm();
    }

    save() {
        this.isWorking = true;
        this.subscription.client_id = this.client.id;
        this.dataManager.POST('api/client-subscriptions', this.subscription).then(
            res => {
                this.onSave.emit(res.item);
                this.alertService.addAlert('Successfully added client', 'success');
                this.isWorking = false;
                this.initForm();
            },
            error => {
                this.errorHandler.handleError(error);
                this.isWorking = false;
            }
        );
    }
}