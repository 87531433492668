import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

/* External Components */
import { GrowlModule } from 'primeng/primeng';
import { DialogModule } from 'primeng/primeng';

import { UtilityModule } from 'techlify-ng';
import { ClientModule } from '../client/client.module';

/* Routing Provider */
import { ReceiptFormatRoutingProvider } from './receipt-format-routes';

import { ReceiptFormatsViewAllComponent } from "./receipt-formats-view-all.componnent";
import { ReceiptFormatDisableButtonSelectComponent } from './receipt-format-disable-button-selector.component';
import { ReceiptFormatEnableButtonSelectComponent } from './receipt-format-enable-button-selector.component';

@NgModule({
    imports: [
        BrowserModule, FormsModule,
        DialogModule,
        GrowlModule,
        BrowserAnimationsModule,
        ReceiptFormatRoutingProvider, UtilityModule,
        ClientModule
    ],
    declarations: [
      ReceiptFormatsViewAllComponent,
      ReceiptFormatDisableButtonSelectComponent,
      ReceiptFormatEnableButtonSelectComponent
    ],
    providers: [],
    exports: []
})
export class ReceiptFormatModule { }
