import { Component, Input, Output, EventEmitter } from "@angular/core";
import { DataManager } from "techlify-ng";
import { AlertService } from "techlify-ng";
import { RequestErrorService } from "techlify-ng";

import { ClientPayment } from "./client-payment.model";
import { ClientPaymentModule } from './client-payment.module';

@Component({
    selector: "client-payment-set-paid-button-selector",
    templateUrl: "./client-payment-set-paid-button-selector.html"
})
export class ClientPaymentSetPaidButtonSelectComponent {

    @Input() payment: ClientPayment;
    @Output() onSave = new EventEmitter<ClientPayment>();

    constructor(private dataManager: DataManager, private alertService: AlertService,
        private errorHandler: RequestErrorService) { }

    setPaid() {
        this.dataManager.PATCH("api/client-payments/" + this.payment.id + "/set-paid", this.payment).then(
            res => {
                this.onSave.emit(res.item);
                this.alertService.addAlert('Successfully updated client payment', "success");
            },
            error => {
                this.errorHandler.handleError(error);
            }
        );
    }

}