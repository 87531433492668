import { Component, OnInit, Input } from '@angular/core';

import { DataManager } from "techlify-ng";
import { AlertService } from "techlify-ng";

import { Role } from './role.model';
import { Permission } from './permission.model';
import { Client } from '../client/client.model';

/**
 * Role Permissions management controller
 * 
 * @author Joshua Kissoon
 * @since 20161013
 */
@Component({
    selector: "role-permissions-management",
    templateUrl: "./role-permissions.html"
})
export class RolePermissionsManagementComponent implements OnInit {
    @Input() client: Client;

    public roles: Role[];
    public permissions: Permission[];

    constructor(private dataManager: DataManager, private alertService: AlertService) { }

    ngOnInit() {
        this.loadRoles();
        this.loadPermissions();
    }

    private loadRoles() {
        const filters: any = { "loadPermissions": true };

        if (this.client) {
            filters.client_id = this.client.id;
        }

        this.dataManager.GET("api/roles", filters).then(
            res => {
                this.roles = new Array<Role>();

                for (var row of res.data) {
                    var role = new Role();
                    role.loadFromJson(row);
                    this.roles.push(role);

                    role.setupBlankPermissionsForNgModel();
                }

            }
        );
    }

    private loadPermissions() {
        this.dataManager.GET("api/permissions", {}).then(
            res => {
                this.permissions = res.data;
            }
        );
    }

    updateRolePermission(role: Role, permission: Permission) {
        const data = {
            "rid": role.id,
            "permission": permission.slug, 
            "client_id": this.client.id
        };

        if (role.hasPermission(permission.slug)) {
            /* Remove the permission  */
            this.dataManager.PATCH("api/roles/" + role.id + "/permissions/" + permission.id + "/remove", data).then(
                res => {
                    this.alertService.addAlert("Permission Removed", "success");
                    role.removePermission(permission);
                }
            );
        }
        else {
            /* Add the permission */
            this.dataManager.PATCH("api/roles/" + role.id + "/permissions/" + permission.id + "/add", data).then(
                res => {
                    this.alertService.addAlert("Permission Added", "success");
                    role.addPermission(permission);
                }
            );
        }
    }

}