import { Component, OnInit } from '@angular/core';

import * as _ from "lodash";


/**
 * Main component that manages the dashboard
 * 
 * @author Joshua Kissoon
 * @since 20161210
 */
@Component({
    selector: 'dashboard',
    templateUrl: './dashboard.html',
    styleUrls: ['./dashboard.css']
})
export class DashboardComponent
{
    

}