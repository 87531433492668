import { Component, Input, Output, EventEmitter } from '@angular/core';

import { DataManager } from 'techlify-ng';
import { AlertService } from 'techlify-ng';
import { RequestErrorService } from 'techlify-ng';

import { AutoDealerInformation } from './auto-dealer-information.model';

/**
 * Controller used to edit a School Information
 * 
 * @author Floyd Kissoon
 * @since 20190729
 */
@Component({
    selector: 'auto-dealer-information-edit',
    templateUrl: './auto-dealer-information-form.html'
})
export class AutoDealerInformationEditComponent {

    @Input() autoDealerInfo: AutoDealerInformation;
    @Output() onSave = new EventEmitter<AutoDealerInformation>();
    private isWorking = false;
    constructor(private dataManager: DataManager, private alertService: AlertService,
        private errorHandler: RequestErrorService) { }

    save() {
        this.isWorking = true;
        this.dataManager.PUT('api/auto-dealer-information/' + this.autoDealerInfo.id, this.autoDealerInfo).then(
            res => {
                this.onSave.emit(res.item);
                this.alertService.addAlert('Successfully updated auto dealer information', 'success');
                this.isWorking = false;
            },
            error => {
                this.errorHandler.handleError(error);
                this.isWorking = false;
            }
        );
    }
}