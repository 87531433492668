import { Component, OnInit, Input } from '@angular/core';
import { DataManager } from "techlify-ng";
import { DateUtils } from "techlify-ng";

import { ClientSubscription } from './client-subscription.model';
import { Client } from '../client/client.model';

/**
 * Component used to view all Client Subbscription
 * 
 * @author Floyd Kissoon
 * @since 20190605
 */
@Component({
    selector: "client-subscriptions-for-client",
    templateUrl: "./client-subscriptions-for-client.html"
})
export class ClientSubscriptionsForClientComponent implements OnInit {
    @Input() client: Client;
    private filters: any;
    private subscriptions: ClientSubscription[];

    constructor(private dataManager: DataManager, private dateUtils: DateUtils) {
        this.filters = {};
        this.filters.sort_by = "id|ASC";
        this.filters.num_items = "25";
    }

    ngOnInit() {
        this.loadData();
    }

    loadData() {
        this.filters.client_id = this.client.id;
        this.dataManager.GET("api/client-subscriptions", this.filters).then(
            res => {
                this.subscriptions = res.data;
            }
        );
    }
}
