import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { DataManager } from 'techlify-ng';
import { AlertService } from 'techlify-ng';

import { User } from './user.model'
import { Role } from './role.model';
import { Client } from '../client/client.model';

/**
 * Component to add a new user
 * 
 * @author Joshua Kissoon
 * @since 20170710
 */
@Component({
    selector: 'user-add',
    templateUrl: './user-form.html'
})
export class UserAdd implements OnInit {
    @Input() client: Client;
    @Output() onSave: EventEmitter<User>;

    public isWorking = false;

    public user: User;
    public roles: Role[];
    public rolesModel: any;

    constructor(private dataManager: DataManager, private alertService: AlertService) {
        this.onSave = new EventEmitter<User>();

        this.user = new User();
        this.roles = new Array<Role>();
        this.rolesModel = {};
    }

    ngOnInit() {
        this.loadRoles();
    }

    loadRoles() {
        this.isWorking = true;
        this.dataManager.GET("api/roles", {"client_id": this.client.id}).then(
            res => {
                this.roles = res.data;
                this.isWorking = false;
            }
        );
    }

    saveUser() {
        this.isWorking = true;

        this.user.client_id = this.client.id;
        var data = JSON.parse(JSON.stringify(this.user));
        data.roles = {};

        for (var i in this.rolesModel) {
            data.roles[i] = this.rolesModel[i];
        }

        this.dataManager.POST("api/users", data).then(
            res => {
                if (!res.item) {
                    this.alertService.addAlert("Failed to add the new user. ", "warning");
                    return;
                }

                this.onSave.emit(res.item);
                this.alertService.addAlert("Successfully added the user. ", "success");
                this.isWorking = false;
            }
        );
    }

}