import { Injectable } from '@angular/core';

import { DataManager } from "techlify-ng";

/**
 * Service to load all entries
 * 
 * @author Joshua Kissoon
 * @since 20190622
 */
@Injectable()
export class ModuleDataService {

    private data: any;

    constructor(private dataManager: DataManager) { }

    getModules(): any {
        var promise;
        if (undefined !== this.data) {
            promise = new Promise((resolve, reject) => {
                resolve(this.data);
            });

            return promise;
        }

        promise = this.dataManager.GET("api/modules", { sortBy: "title|ASC" });

        promise.then(
            res => {
                this.data = res;
            }
        );

        return promise;
    }
}