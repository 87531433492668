import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { DataManager } from "techlify-ng";
import { AlertService } from "techlify-ng";


/**
 * Controller to allow the user to update his/her own password
 * 
 * @author Floyd Kissoon
 * @since 20170828
 */
@Component({
    selector: "user-password-update",
    templateUrl: "./user-password-edit.html"
})
export class UserPasswordEditComponent {
    public data: any;

    constructor(private dataManager: DataManager, private alertService: AlertService, private router: Router) {
        this.data = {};
        this.data.currentPassword = "";
        this.data.newPassword = "";
    }

    saveNewPassword() {
        this.dataManager.PATCH("api/user/current/update-password", this.data).then(
            res => {
                if (res.success != true) {
                    var message = res.message ? res.message : "Failed to update your password, please try again";
                    this.alertService.addAlert(message, "warning");
                    return;
                }

                this.alertService.addAlert("Successfully changed your password", "success");
                this.router.navigate(["/dashboard"]);

            }
        );
    }
}