import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

import { ClientDataService } from "./client-data.service";
import { Client } from "./client.model";

@Component({
  selector: "client-selector",
  templateUrl: "./client-selector.html"
})
export class ClientSelectComponent implements OnInit {
  private clients: Client[];

  @Input() id: number;
  @Output() idChange: EventEmitter<number>;

  constructor(private dataService: ClientDataService) {
    this.clients = new Array<Client>();
    this.idChange = new EventEmitter<number>();
  }

  ngOnInit() {
    this.loadData();
  }

  private loadData() {
    this.dataService.getClients().then(
      res => {
        this.clients = res.data;
      }
    );
  }

  itemSelected() {
    this.idChange.emit(this.id);
  }

}
