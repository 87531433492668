import { Component, Input, Output, EventEmitter } from "@angular/core";
import { DataManager } from "techlify-ng";

import { File } from "./File.model";

/**
 * Component used to delete a Sale File
 * 
 * @author Joshua Kissoon
 * @since 20180125
 */
@Component({
    selector: "file-delete",
    templateUrl: "./file-delete.html",
})
export class FileDeleteComponent
{

    @Input() file: File;
    @Output() onSave = new EventEmitter<File>();

    constructor(private dataManager: DataManager) { }

    deleteSaleFile()
    {
        this.dataManager.DELETE("api/files/" + this.file.id).then(
            res =>
            {
                this.onSave.emit(this.file);
            }
        );
    }
}
