import { Permission } from "./permission.model";

/**
 * Class representation of a Role
 * 
 * @author Joshua Kissoon
 * @since 20170710
 */
export class Role {
    id: number;
    slug: string;
    label: string;
    client_id: number;

    permissions: Permission[];
    permissionsModel: any = {};

    constructor() {
        this.permissions = new Array<Permission>();
    }

    public loadFromJson(data: any) {
        this.id = data.id;
        this.slug = data.slug;
        this.label = data.label;

        if (data.permissions != undefined) {
            this.permissions = data.permissions;
        }

    }

    hasPermission(perm: string): boolean {

        for (var i in this.permissions) {
            var permission = this.permissions[i];

            if (permission.slug == perm) {
                return true;
            }
        }

        return false;
    }

    removePermission(perm: Permission) {
        for (var key in this.permissions) {
            var permission = this.permissions[key];

            if (permission.slug == perm.slug) {
                var index = this.permissions.indexOf(permission);
                this.permissions.splice(index, 1);
                return;
            }
        }
    }

    addPermission(permission: Permission) {
        this.permissions.push(permission);
    }

    /**
     * Method that sets up a permissions variable to work as an ngModel
     * 
     * @author Joshua Kissoon
     * @since 20161014
     */
    setupBlankPermissionsForNgModel() {
        for (var perm of this.permissions) {
            this.permissionsModel[perm.slug] = true;
        }
    }
}
