import { Component, OnInit } from '@angular/core';

import { DataManager } from 'techlify-ng';
import { Module } from './module.model';

/**
 * Controller used to view all modules
 * 
 * @author Floyd Kissoon
 * @since 20190604
 */
@Component({
    selector: 'modules-view-all',
    templateUrl: './modules-view-all.html'
})
export class ModulesViewAllComponent implements OnInit {
    private modules: Module[];
    private filters: any;

    private isWorking = false;
    constructor(private dataManager: DataManager) {
        this.filters = {};
        this.filters.sort_by = 'name|ASC';
        this.filters.num_items = '25';
        this.filters.name = '';
    }

    ngOnInit() {
        this.loadData();
    }

    loadData() {
        this.isWorking = true;
        this.dataManager.GET("api/modules", this.filters).then(
            res => {
                this.modules = res.data;
                this.isWorking = false;
            });
    }
}