import { Component, OnInit, Input } from '@angular/core';

import { DataManager } from 'techlify-ng';
import { ClientPayment } from './client-payment.model';
import { ClientSubscription } from '../client-subscription/client-subscription.model';

/**
 * Controller used to view all client payments for a subscription
 * 
 * @author Floyd Kissoon
 * @since 20190605
 */
@Component({
    selector: 'client-payments-for-subscription',
    templateUrl: './client-payments-for-subscription.html'
})
export class ClientPaymentsForSubscriptionComponent implements OnInit {
    private payments: ClientPayment[];
    private filters: any;

    @Input() subscription: ClientSubscription;

    private isWorking = false;
    constructor(private dataManager: DataManager) {
        this.filters = {};

    }
    ngOnInit() {
        this.loadData();
    }

    loadData() {
        this.isWorking = true;
        this.filters.subscription_id = this.subscription.id;
        this.dataManager.GET("api/client-payments", this.filters).then(
            res => {
                this.payments = res.data;
                this.isWorking = false;
            });
    }
}