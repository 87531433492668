import { Component, OnInit } from '@angular/core';

import { DataManager } from 'techlify-ng';
import { Client } from './client.model';

/**
 * Controller used to view all clients
 * 
 * @author Floyd Kissoon
 * @since 20190604
 */
@Component({
    selector: 'clients-view-all',
    templateUrl: './clients-view-all.html'
})
export class ClientsViewAllComponent implements OnInit {
    private clients: Client[];
    private filters: any;

    private isWorking = false;
    constructor(private dataManager: DataManager) {
        this.filters = {};
        this.filters.sort_by = 'name|ASC';
        this.filters.num_items = '50';
        this.filters.name = '';
        this.filters.email = '';
        this.filters.phone = '';
        this.filters.address = '';
    }

    ngOnInit() {
        this.loadData();
    }

    loadData() {
        this.isWorking = true;
        this.dataManager.GET("api/clients", this.filters).then(
            res => {
                this.clients = res.data;
                this.isWorking = false;
            });
    }
}