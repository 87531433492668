import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

/* External Components */
import { GrowlModule } from 'primeng/primeng';
import { DialogModule } from 'primeng/primeng';
import { UtilityModule } from 'techlify-ng';

/* Routing Provider */
import { ModuleRoutingProvider } from './module.routes';

import { ModulesViewAllComponent } from './modules-view-all.component';
import { ModuleEditComponent } from './module-edit.component';
import { ModuleDeleteComponent } from './module-delete.component';
import { ModuleViewComponent } from './module-view.component';

import { ModuleDataService } from './module-data.service';

import { ModulePackagesForModuleComponent } from './module-package/module-packages-for-module.component';
import { ModulePackageSelectComponent } from './module-package/module-package-select.component';


@NgModule({
    imports: [
        BrowserModule, FormsModule,
        DialogModule,
        GrowlModule,
        BrowserAnimationsModule,
        ModuleRoutingProvider, UtilityModule
    ],
    declarations: [
        ModulesViewAllComponent, 
        ModuleEditComponent, 
        ModuleDeleteComponent,
        ModuleViewComponent,
        ModulePackagesForModuleComponent, 
        ModulePackageSelectComponent
    ],
    providers: [ModuleDataService],
    exports: [ModulePackageSelectComponent]
})
export class ModuleModule { }
