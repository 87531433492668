import { Component, OnInit } from '@angular/core';

import { DataManager } from 'techlify-ng';
import { ReceiptFormat } from './receipt-format.model';

/**
 * Controller used to view all receipt formats
 *
 * @author Floyd Kissoon
 * @since 20191006
 */
@Component({
  selector: 'receipt-formats-view-all',
  templateUrl: './receipt-formats-view-all.html'
})
export class ReceiptFormatsViewAllComponent implements OnInit {
  private formats: ReceiptFormat[];
  private filters: any;

  private isWorking = false;
  constructor(private dataManager: DataManager) {
    this.filters = {};
    this.filters.sort_by = 'title|ASC';
    this.filters.num_items = '50';
    this.filters.title = '';
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.isWorking = true;
    this.dataManager.GET("api/receipt-formats", this.filters).then(
      res => {
        this.formats = res.data;
        this.isWorking = false;
      });
  }
}
