import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { RoutingProvider } from './app.routes';
import { AppComponent } from './app.component';

/* Global Util Modules */
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client'
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { GrowlModule } from 'primeng/primeng';

import { DashboardModule } from "./modules/dashboard/dashboard.module";
import { UserModule } from './modules/user/user.module';
import { ClientModule } from './modules/client/client.module';
import { ModuleModule } from './modules/module/module.module';
import { ClientSubscriptionModule } from './modules/client-subscription/client-subscription.module';
import { ClientPaymentModule } from './modules/client-payment/client-payment.module';
import { HelperModule } from './modules/helper/helper.module'
import { AutoDealerInformationModule } from './modules/auto-dealer-information /auto-dealer-information.module';
import { FileUploaderModule } from './modules/file-uploader/file-uploader.module';
import { ReceiptFormatModule } from './modules/receipt-format/receipt-format.moduel';

import { TechlifyServicesModule } from "techlify-ng";
import { TechlifyConfig } from "techlify-ng";

declare var require: any;
var configInstance = new TechlifyConfig(require("./config/config-eauto-prod.json"));

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    HttpClientModule,
    RoutingProvider,
    LoadingBarHttpClientModule, LoadingBarRouterModule,
    GrowlModule,
    TechlifyServicesModule,
    UserModule,
    DashboardModule, ClientModule, ModuleModule,
    ClientSubscriptionModule, ClientPaymentModule,
    HelperModule, AutoDealerInformationModule,
    FileUploaderModule,
    ReceiptFormatModule
  ],
  providers: [
    { provide: TechlifyConfig, useValue: configInstance }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
