/**
 * Class representation of a Module
 * 
 * @author Floyd Kissoon
 * @since 20190604
 */
export class Module {
    id: number;
    description: string;
    creator_id: number;
    created_at: Date;
    updated_at: Date;

    creator: any;
    packages: any;

    constructor() {
        this.creator = {};
    }
}