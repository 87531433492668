import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { Router } from "@angular/router"

import { AlertService } from "techlify-ng";
import { DataManager } from "techlify-ng";
import { RequestErrorService } from "techlify-ng";

import { Helper } from "./helper.model";

/**
 * Controller that adds a new Helper
 * 
 * @author Saravana Kumar
 * @since 20190719
 */
@Component({
    selector: "helper-add",
    templateUrl: "./helper-form.html"
})
export class HelperAddComponent {
    @Output() onSave = new EventEmitter<Helper>();

    private helper: Helper;
    private isWorking = false;

    constructor(private dataManager: DataManager, private alertService: AlertService,
        private router: Router, private errorHandler: RequestErrorService) { 
            this.helper = new Helper();
        }

    save() {
        this.isWorking = true;
        this.dataManager.POST("api/helpers", this.helper).then(
            response => {
                this.alertService.addAlert("Successfully added the helper. ", "success");
                this.onSave.emit(response.item);
                this.isWorking = false;
            }, error => {
                this.errorHandler.handleError(error);
                this.isWorking = false;
            }
        );
    }
}                
