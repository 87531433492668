import { Component, Output, EventEmitter, Input } from '@angular/core';

import { DataManager } from "techlify-ng";
import { AlertService } from "techlify-ng";
import { RequestErrorService } from 'techlify-ng';

import { Role } from './role.model';
import { Client } from '../client/client.model';

/**
 * Component used to add a new Role
 * 
 * @author Joshua Kissoon
 * @since 20160723
 */
@Component({
    selector: 'rbac-role-add',
    templateUrl: './role-form.html',
})
export class RoleAddComponent {
    @Output() onSave: EventEmitter<Role>;
    @Input() client: Client;

    role = new Role();

    constructor(private dataManager: DataManager, private alertService: AlertService,
        private errorHandler: RequestErrorService) {
        this.onSave = new EventEmitter<Role>()
    }


    saveRole() {
        this.role.client_id = this.client.id;
        this.dataManager.POST("api/roles", this.role).then(
            res => {
                if (!res.item) {
                    this.alertService.addAlert("Unable to add the role, please try again later. ", "warning");
                    return;
                }

                this.onSave.emit(res.item);
                this.alertService.addAlert('Successfully added role.', "success");
            },
            error => {
                this.errorHandler.handleError(error);
            }
        );
    }
}
