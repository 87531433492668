import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

/* External Components */
import { GrowlModule } from 'primeng/primeng';
import { DialogModule } from 'primeng/primeng';
import { UtilityModule } from 'techlify-ng';
import { CalendarModule } from 'primeng/primeng';

import { ClientPaymentsForSubscriptionComponent } from './client-payments-for-subscription.component';
import { ClientPaymentAddComponent } from './client-payment-add.component';
import { ClientPaymentEditComponent } from './client-payment-edit.component';
import { ClientPaymentDeleteComponent } from './client-payment-delete.component';
import { ClientPaymentSetPaidButtonSelectComponent } from './client-payment-set-paid-button-selector.component';

@NgModule({
    imports: [
        BrowserModule, FormsModule,
        DialogModule,
        GrowlModule,
        BrowserAnimationsModule,
        UtilityModule, CalendarModule
    ],
    declarations: [
        ClientPaymentsForSubscriptionComponent, ClientPaymentAddComponent, ClientPaymentEditComponent,
        ClientPaymentDeleteComponent, ClientPaymentSetPaidButtonSelectComponent
    ],
    providers: [],
    exports: [ClientPaymentsForSubscriptionComponent]
})
export class ClientPaymentModule { }
