import { Component, Input, Output, EventEmitter } from '@angular/core';

import { DataManager } from "techlify-ng";
import { AlertService } from "techlify-ng";

import { Role } from './role.model';

/**
 * Component used to edit a new Role
 * 
 * @author Joshua Kissoon
 * @since 20160818
 */
@Component({
    selector: 'rbac-role-edit',
    templateUrl: './role-form.html',
})
export class RoleEditComponent {
    @Input() role: Role;
    @Output() onSave: EventEmitter<Role>;

    constructor(private dataManager: DataManager, private alertService: AlertService) {
        this.onSave = new EventEmitter<Role>()
    }

    saveRole() {
        this.dataManager.PATCH("api/roles/" + this.role.id, this.role).then(
            res => {
                if (!res.item) {
                    this.alertService.addAlert("Failed to update the role, please try again later or contact your site administrator. ", "warning");
                    return;
                }

                this.onSave.emit(res.item);
            }
        );
    }
}