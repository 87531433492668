import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { DataManager } from "techlify-ng";
import { AlertService } from "techlify-ng";
import { RequestErrorService } from "techlify-ng";

import { Helper } from "./helper.model";

/**
 * Controller used to edit a helper
 * 
 * @author Saravana kumar
 * @since 20190628
 */
@Component({
    selector: 'helper-edit',
    templateUrl: "./helper-form.html"
})
export class HelperEditComponent implements OnInit {

    @Input() helper: Helper;
    @Output() onSave = new EventEmitter<Helper>();
    private isWorking = false;

    constructor(private dataManager: DataManager, private alertService: AlertService,
        private errorHandler: RequestErrorService) { }

    ngOnInit() { }

    save() {
        this.isWorking = true;
        this.dataManager.PUT("api/helpers/" + this.helper.id, this.helper).then(
            response => {
                this.alertService.addAlert("Successfully updated the helper record", "success");
                this.onSave.emit(response.item);
                this.isWorking = false;
            }, error => {
                this.errorHandler.handleError(error);
                this.isWorking = false;
            }
        );
    }
}