import { Component, Input, Output, EventEmitter } from '@angular/core';

import { DataManager } from 'techlify-ng';
import { AlertService } from 'techlify-ng';
import { RequestErrorService } from 'techlify-ng';

import { ClientPayment } from './client-payment.model';

/**
 * Controller used to edit a Client Payment
 * 
 * @author Floyd Kissoon
 * @since 20190604
 */
@Component({
    selector: 'client-payment-edit',
    templateUrl: './client-payment-form.html'
})
export class ClientPaymentEditComponent {

    @Input() payment: ClientPayment;
    @Output() onSave = new EventEmitter<ClientPayment>();
    private isWorking = false;
    constructor(private dataManager: DataManager, private alertService: AlertService,
        private errorHandler: RequestErrorService) { }

    save() {
        this.isWorking = true;
        this.dataManager.PUT('api/client-payments/' + this.payment.id, this.payment).then(
            res => {
                this.onSave.emit(res.item);
                this.alertService.addAlert('Successfully updated client payment', 'success');
                this.isWorking = false;
            },
            error => {
                this.errorHandler.handleError(error);
                this.isWorking = false;
            }
        );
    }
}