import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { enableProdMode } from '@angular/core';
enableProdMode();

/* External Components */
import { GrowlModule } from 'primeng/primeng';
import { DialogModule } from 'primeng/primeng';

/* Routing Provider */
import { DashboardRoutingProvider } from './dashboard.routes';

import { DashboardComponent } from './dashboard.component';

@NgModule({
    imports: [
        BrowserModule, FormsModule,
        DashboardRoutingProvider, DialogModule,
        GrowlModule,
        BrowserAnimationsModule
    ],   
    
    // module dependencies
    declarations: [
        DashboardComponent
    ],
    providers: [],
    exports: []
})
export class DashboardModule { }
