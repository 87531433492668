import { Component, OnInit, Input } from "@angular/core";
import { DataManager } from "techlify-ng";

import { File } from "./File.model";

/**
 * Component used to view a set of files for a Specific Model
 * 
 * @author Joshua Kissoon
 * @since 20180125
 */
@Component({
    selector: "files-for-model",
    templateUrl: "./files-for-model.html",
})
export class FilesForModelComponent implements OnInit
{
    private displayFileAddDialog = false;
    @Input() owner_id: number;
    @Input() owner_type: string;

    private filters: any;
    private files: File[];

    constructor(private dataManager: DataManager)
    {
        this.filters = {};
        this.filters.sort_by = "id|DESC";
    }

    ngOnInit()
    {
        this.filters.owner_id = this.owner_id;
        this.filters.owner_type = this.owner_type;
        this.loadFiles();
    }

    loadFiles()
    {
        this.dataManager.GET("api/files", this.filters).then(
            res =>
            {
                this.files = res.items;
            }
        );
    }

    handleFileUpdated()
    {
        this.displayFileAddDialog = false;
        this.loadFiles();
    }
}