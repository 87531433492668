import { Component, Input, Output, EventEmitter } from "@angular/core";
import { DataManager } from "techlify-ng";
import { RequestErrorService } from "techlify-ng";
import { AlertService } from "techlify-ng";

import { ReceiptFormat } from "./receipt-format.model";

@Component({
  selector: "receipt-format-disable-button-selector",
  templateUrl: "./receipt-format-disable-button-selector.html"
})
export class ReceiptFormatDisableButtonSelectComponent {

  @Input() format: ReceiptFormat;
  @Output() onSave = new EventEmitter<ReceiptFormat>();

  constructor(private dataManager: DataManager,
    private alertService: AlertService,
    private errorHandler: RequestErrorService) { }

  disable() {
    this.dataManager.PATCH("api/receipt-formats/" + this.format.id + "/status-update-inactive", this.format).then(
      res => {
        this.onSave.emit(res.item);
        this.alertService.addAlert('Successfully disable Receipt Format.', "success");
      },
      error => {
        this.errorHandler.handleError(error);
      }
    );
  }

}
