import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from 'techlify-ng';
import { AlertService } from 'techlify-ng';
import { DataManager } from 'techlify-ng';
import { CurrentUserService } from "./modules/user/current-user.service";

import { User } from "techlify-ng";

/**
 * Main class for the application
 * 
 * @author Joshua Kissoon
 * @since 20170320
 * 
 * @todo This class is called everytime the page is refreshed - reload the user object of a logged in user in case changes were made
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  private htmlClass: string;
  private user: User;
  private loading = false;

  constructor(private dataManager: DataManager, private alertService: AlertService,
    private authService: AuthService, private router: Router, private currentUserService: CurrentUserService) {
    this.user = new User();

    this.loading = true;
    this.currentUserService.getCurrentUser().then(
      res => {
        this.user = this.currentUserService.getUser();
        this.loading = false;
      });
  }

  ngOnInit() {
    var self = this;

    this.router.events.subscribe(function () {
      self.htmlClass = self.cleanupClassString(self.router.url);
    });
  }

  cleanupClassString(val: string): string {
    if (val.charAt(0) === '/') {
      val = val.substr(1);
    }

    var parts = val.split("/");
    var classes = "";
    var classConcat = "page";

    for (var i in parts) {
      var part = parts[i];

      classConcat += "-" + part;

      classes += " " + classConcat;
    }

    if (parts[0] != "home") {
      classes += " not-home";
    }

    return classes;
  }

  /**
   * Logs the user out
   */
  signOut() {
    if (!this.authService.isLoggedIn()) {
      return;
    }

    this.dataManager.POST("api/user/logout", {}).then(
      res => {
        if (res.success == false) {
          this.alertService.addAlert("Failed to sign off of Auto Manager", "warn");
          return;
        }

        this.authService.signOut();
        this.alertService.addAlert("Succesfully Signed off of Auto Manager", "success");

        /* Reload the page so that Service data is cleared and it will auto-redirect to login */
        location.reload();
      }
    );
  }
}