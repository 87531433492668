/**
 * Class representation of a Client
 * 
 * @author Floyd Kissoon
 * @since 20190603
 */
export class Client {
    id: number;
    name: string;
    email: string;
    phone: string;
    address: string;
    created_at: Date;
    updated_at: Date;
    creator_id: number;

    creator: any;

    constructor() {
        this.creator = {}
    }
}