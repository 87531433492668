import { Component, OnInit, Input } from '@angular/core';

import { DataManager } from 'techlify-ng';
import { ModulePackage } from './module-package.model';
import { Module } from '../module.model';

/**
 * Controller used to view all module packages
 * 
 * @author Floyd Kissoon
 * @since 20190605
 */
@Component({
    selector: 'module-packages-for-module',
    templateUrl: './module-packages-for-module.html'
})
export class ModulePackagesForModuleComponent implements OnInit {
    private packages: ModulePackage[];
    private filters: any;

    @Input() module: Module;

    private isWorking = false;
    constructor(private dataManager: DataManager) {
        this.filters = {};
        this.filters.sort_by = 'monthly_cost|ASC';
        this.filters.num_items = '25';
        this.filters.name = '';
    }

    ngOnInit() {
        this.loadData();
    }

    loadData() {
        this.isWorking = true;
        this.filters.module_id = this.module.id;
        this.dataManager.GET("api/module-packages", this.filters).then(
            res => {
                this.packages = res.data;
                this.isWorking = false;
            });
    }
}