/**
 * Class representation of an File
 * 
 * @author Joshua Kissoon 
 * @since 20180125
 */
export class File
{
    id: number;
    owner_id: number;
    owner_type: string;
    filename: string;
    title: string;
}
