import { Component, Output, EventEmitter } from '@angular/core';

import { DataManager } from 'techlify-ng';
import { AlertService } from 'techlify-ng';
import { RequestErrorService } from 'techlify-ng';

import { Client } from "./client.model";

/**
 * Controller that adds a new client to the system
 * 
 * @author Floyd Kissoon
 * @since 20190604
 */
@Component({
    selector: 'client-add',
    templateUrl: './client-form.html'
})
export class ClientAddComponent {

    @Output() onSave = new EventEmitter<Client>();

    private client: Client;

    private isWorking = false;

    constructor(private dataManager: DataManager, private alertService: AlertService,
        private errorHandler: RequestErrorService) {
        this.initForm();
    }

    initForm() {
        this.client = new Client();
    }

    save() {
        this.isWorking = true;

        this.dataManager.POST('api/clients', this.client).then(
            res => {
                this.onSave.emit(res.item);
                this.alertService.addAlert('Successfully added client', 'success');
                this.isWorking = false;
                this.initForm();
            },
            error => {
                this.errorHandler.handleError(error);
                this.isWorking = false;
            }
        );
    }
}