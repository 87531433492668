import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from 'techlify-ng';

const routes: Routes = [
];
import { ModulesViewAllComponent } from "./modules-view-all.component";
routes.push({ path: 'modules', component: ModulesViewAllComponent, canActivate: [AuthGuard] });

import { ModuleViewComponent } from './module-view.component';
routes.push({ path: 'module/:id/view', component: ModuleViewComponent, canActivate: [AuthGuard] });

export const ModuleRoutingProvider = RouterModule.forRoot(routes, { useHash: true });