import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DataManager } from 'techlify-ng';
import { RequestErrorService } from 'techlify-ng';
import { AlertService } from 'techlify-ng';

import { ClientSubscription } from './client-subscription.model';

/**
 * Controller used to view a single client subscription
 * 
 * @author Floyd Kissoon
 * @since 20190604
 */
@Component({
    selector: 'client-subscription-view',
    templateUrl: './client-subscription-view.html'
})
export class ClientSubscriptionViewComponent implements OnInit {

    private subscription = new ClientSubscription();

    constructor(private dataManager: DataManager, private route: ActivatedRoute, private errorHandler: RequestErrorService,
        private alertService: AlertService, ) { }

    ngOnInit() {
        this.loadData();
    }

    loadData() {
        this.route.params.subscribe(params => {
            if (params['id'] !== undefined) {
                let id = params['id'];

                this.dataManager.GET('api/client-subscriptions/' + id).then(
                    res => {
                        this.subscription = res.item;
                    },
                    error => {
                        this.errorHandler.handleError(error);
                    }
                );
            }
        });
    }
}
