import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from 'techlify-ng';

const routes: Routes = [
];
import { ReceiptFormatsViewAllComponent } from "./receipt-formats-view-all.componnent";
routes.push({ path: 'receipt-formats', component: ReceiptFormatsViewAllComponent, canActivate: [AuthGuard] });

export const ReceiptFormatRoutingProvider = RouterModule.forRoot(routes, { useHash: true });
