import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { enableProdMode } from '@angular/core';
enableProdMode();

/* External Components */
import { GrowlModule } from 'primeng/primeng';
import { CalendarModule } from 'primeng/primeng';
import { DialogModule } from 'primeng/primeng';

/* Routing Provider */
import { ClientSubscriptionRoutingProvider } from './client-subscription.routes';

import { ClientSubscriptionsForClientComponent } from './client-subscriptions-for-client.component';
import { ClientSubscriptionViewComponent } from './client-subscription-view.component';
import { ClientSubscriptionAddComponent } from './client-subscription-add.component';

import { ClientPaymentModule } from '../client-payment/client-payment.module';
import { ModuleModule } from '../module/module.module';

@NgModule({
    imports: [
        BrowserModule, FormsModule,
        ClientSubscriptionRoutingProvider, CalendarModule, DialogModule,
        GrowlModule,
        BrowserAnimationsModule, ClientPaymentModule, 
        ModuleModule, 
    ],
    declarations: [
        ClientSubscriptionsForClientComponent,
        ClientSubscriptionViewComponent,
        ClientSubscriptionAddComponent
    ],
    providers: [],
    exports: [ClientSubscriptionsForClientComponent]
})
export class ClientSubscriptionModule { }
