import { Component, Output, EventEmitter, Input } from '@angular/core';

import { DataManager } from 'techlify-ng';
import { AlertService } from 'techlify-ng';
import { RequestErrorService } from 'techlify-ng';

import { ClientPayment } from "./client-payment.model";
import { ClientSubscription } from '../client-subscription/client-subscription.model';

/**
 * Controller that adds a new client payment to the system
 * 
 * @author Floyd Kissoon
 * @since 20190604
 */
@Component({
    selector: 'client-payment-add',
    templateUrl: './client-payment-form.html'
})
export class ClientPaymentAddComponent {

    @Output() onSave = new EventEmitter<ClientPayment>();
    @Input() subscription: ClientSubscription;

    private payment: ClientPayment;

    private isWorking = false;

    constructor(private dataManager: DataManager, private alertService: AlertService,
        private errorHandler: RequestErrorService) {
        this.initForm();
    }

    initForm() {
        this.payment = new ClientPayment();
        this.payment.amount_due = 0;
        this.payment.amount_paid = 0;
        this.payment.paid_on = new Date();
    }

    save() {
        this.isWorking = true;

        this.payment.subscription_id = this.subscription.id;
        this.dataManager.POST('api/client-payments', this.payment).then(
            res => {
                this.onSave.emit(res.item);
                this.alertService.addAlert('Successfully added client payment', 'success');
                this.isWorking = false;
                this.initForm();
            },
            error => {
                this.errorHandler.handleError(error);
                this.isWorking = false;
            }
        );
    }
}