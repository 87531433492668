/**
 * Class representation of a Auto Dealer Information
 *
 * @author Floyd Kissoon
 * @since 20190815
 */
export class AutoDealerInformation
{
    id: number;
    client_id: number;
    name: string;
    address: string;
    phone: string;
    email: string;
    logo: string;
    tin: string;
    motto: string;
    creator_id: number;
    created_at: Date;
    updated_at: Date;
    website: string;
    fax:string;
    contract_signatory: string;
    license_office_signatory: string;
    bank_letter_signatory: string;
    insurance_letter_signatory: string;
    third_party_insurance_letter_signatory: string;
    sale_details_letter_signatory: string;
    reminder_letter_signatory: string;
    full_payment_letter_signatory: string;
    seizure_letter_signatory: string;
    seizure_insurance_letter_signatory: string;
    bill_of_sale_code: string;
    sale_contract_code: string;

    /* Relations */
    creator: any;
    client: any;

    constructor() {
        this.creator = {};
        this.client = {};
    }
}
