import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { enableProdMode } from '@angular/core';
enableProdMode();

/* External Components */
import { GrowlModule } from 'primeng/primeng';
import { DialogModule } from 'primeng/primeng';

/* Routing Provider */
import { UserRoutingProvider } from './user.routes';

import { UserSelectorComponent } from './user-selector.component';
import { UserDataService } from './user-data.service';

/* RBAC & User Components */
import { CurrentUserService } from "./current-user.service";

import { RoleAddComponent } from './role-add.component';
import { RoleEditComponent } from './role-edit.component';
import { RolesViewAllComponent } from './roles-view-all.component';
import { RolePermissionsManagementComponent } from './role-permissions-management.component';

import { UserLoginComponent } from './login.component';
import { UsersViewAll } from './users-view-all.component';
import { UserAdd } from './user-add.component';
import { UserEdit } from './user-edit.component';
import { UserDeleteComponent } from './user-delete.component';
import { UserPasswordEditComponent } from './user-password-edit.component';
import { UsersForClientComponent } from './users-for-client.component';

@NgModule({
    imports: [
        BrowserModule, FormsModule,
        UserRoutingProvider, DialogModule, GrowlModule,
        BrowserAnimationsModule
    ],
    declarations: [
        RoleAddComponent, RoleEditComponent, RolesViewAllComponent, RolePermissionsManagementComponent,
        UserLoginComponent, UserPasswordEditComponent, UsersViewAll, UserAdd, UserEdit, UserDeleteComponent,
        UserSelectorComponent, 
        UsersForClientComponent
    ],
    providers: [UserDataService, CurrentUserService],
    exports: [
        UserSelectorComponent, 
        UsersForClientComponent, 
        RolesViewAllComponent, 
        RolePermissionsManagementComponent
    ]
})
export class UserModule { }
