import { Component, Input, Output, EventEmitter } from '@angular/core';

import { DataManager } from "techlify-ng";
import { AlertService } from "techlify-ng";

import { User } from "techlify-ng";

/**
 * Controller used to delete a User
 * 
 * @author Joshua Kissoon
 * @since 20170710
 */
@Component({
    selector: 'user-delete',
    templateUrl: "./user-delete.html"
})
export class UserDeleteComponent {
    @Input() user: User;
    @Output() onSave: EventEmitter<User>;

    public isWorking = false;

    constructor(private dataManager: DataManager, private alerter: AlertService) {
        this.onSave = new EventEmitter<User>();
    }

    deleteUser() {
        this.isWorking = true;
        this.dataManager.DELETE("api/users/" + this.user.id).then(
            response => {
                if (!response.success || response.success === false) {
                    var message = response.message ? response.message : "Unable to delete the user record, please contact your site administrator";
                    this.alerter.addAlert(message, "warning");
                    return;
                }

                this.onSave.emit(this.user);
                this.alerter.addAlert("The user record was successfully deleted. ", "success");
                this.isWorking = false;
            }
        );
    }
}