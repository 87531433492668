import { Injectable } from '@angular/core';

import { DataManager } from "techlify-ng";

/**
 * Service that provides Clients Data
 *
 * @author Floyd Kissoon
 * @since 20191006
 */
@Injectable()
export class ClientDataService {

  private data: any;

  constructor(private dataManager: DataManager) {

  }

  getClients() {
    var promise;
    if (undefined !== this.data) {
      promise = new Promise((resolve, reject) => {
        resolve(this.data);
      });

      return promise;
    }

    promise = this.dataManager.GET("api/clients", { sort_by: "name|ASC" });

    promise.then(
      res => {
        this.data = res;
      }
    );

    return promise;
  }
}
